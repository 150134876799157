<template>
    <div class="book-analytics-details-page">
        <v-row justify="center">
            <v-col md="8">
                <v-card class="my-2" :elevation="$vuetify.breakpoint.mdAndDown ? 0 : 2">
                <intract-smart-list
                    :endpoint="booksAnalyticsEndpoint"  
                    ref="book-analytics-smart-list"
                    disable-search
                    :filter-fields="filterFields"
                >
                    <template v-slot:custom-filter--date_published>
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                solo
                                :value="formattedDateString"
                                label="Filter by Date"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                hide-details="auto"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date">
                            <v-row no-gutters>
                            <v-col class="text-right">
                                <v-btn text color="primary" @click="modal = false">
                                Cancel
                                </v-btn>
                                <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                                >
                                OK
                                </v-btn>
                            </v-col>
                            </v-row>
                        </v-date-picker>
                        </v-dialog>
                    </template>
                    <template v-slot:list-item="{item: topic}">
                        <v-card
                            class="mx-2 my-3"
                        >
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-img :src="topic.published_by.image"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content class="subtitle-2">
                                    <v-list-item-title>
                                        {{ topic.title }} 
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text--primary">
                                        {{ topic.is_activity ? topic.room.sections : `${topic.subject.name} - ${topic.subject.sections}`  }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        {{ topic.published_by.full_name }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <p class="my-0 caption grey--text">
                                    {{ moment(topic.date_published).format("hh:mmA, Do MMM") }}
                                </p>
                            </v-list-item>

                        </v-card>
                    </template>
                </intract-smart-list>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import moment from "moment";
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'BookAnalyticsDetails',
    mixins: [Mixins.essentials,],
    components: {
        IntractSmartList,
    },
    data() {
        return {
            moment: moment,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            staff: []
        }
    },
    computed:{
        ...mapGetters(['allRooms','isLoading']),
        booksAnalyticsEndpoint(){
            var url;
            if(this.$route.name == "KrafBookAnalyticsDetails"){
                url = this.Helper.addUrlParams(this.endpoints.krafBookAnalyticsViewSet, ["date_published="+moment(this.date).format("YYYY-MM-DD"), 'is_activity=false'])
            }
            else{
                url = this.Helper.addUrlParams(this.endpoints.krafBookAnalyticsViewSet, ["date_published="+moment(this.date).format("YYYY-MM-DD"), 'is_activity=true'])
            }
            return url
        },
        filterFields() {
            return {
                published_by: {
                    model: null,
                    multiple: false,
                    items: this.staff,
                    label: 'Published by',
                    itemValue: 'id',
                    itemText: 'full_name',
                    param: 'published_by'
                },
                room: {
                    model: null,
                    multiple: false,
                    items: this.allRooms,
                    label: "Filter by Classroom",
                    itemValue: 'id',
                    itemText: 'sections',
                    param: 'subject__room'
                },
                date_published: {
                    model: null,
                    label: "Filter by Date",
                    custom: true,
                },
            }
        },
        formattedDateString() {
            if(!this.date) return ""
            return moment(this.date).format("ll")
        },
    },
    methods: {
        ...mapActions(["setLoading", "showSnackbar"]),
        async getStaffDetails() {
            var url = this.Helper.addUrlParams(this.endpoints.staffViewSet,'paginate=false');
            this.staff = await this.api.call(this.essentials, url);
        },
    },
    created(){
      this.getStaffDetails();
    }
}
</script>
